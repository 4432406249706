body {
  display: flex;
  justify-content: center;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Helvetica Neue', 'Ubuntu', sans-serif;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  width: 400px;
  height: 112px;
  border-radius: 6px;
}
p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.normal-link {
  color: #4a8c8a !important;
}

.ls-ahref {
  color: #4a8c8a;
}

.highlighted-text {
  color: #4a8c8a;
}


/* Header */

.header-analyses {
  margin-right: 1rem;
}

/* Mobile Menu */

@media (max-width: 1249px) {
  .ptf-nav.ptf-nav--default {
    display: none;
  }
  .ptf-navbar-search {
    display: none;
  }
}


@media (min-width: 1250px) {  /* Adjust 768px based on your definition of 'mobile' screen size */
  .lnir-menu-alt-5 {
    display: none;
    pointer-events: none;
  }

  .js-offcanvas-menu-toggle.bar {
    display: none;         /* Hides the parent element */
    pointer-events: none;  /* Makes the parent element non-interactive */
}

}

.sidebar-menu_wrapper ul {
  list-style-type: none;
  font-size: 2em;
}

.sidebar-menu_wrapper ol {
  list-style-type: none;
}



/* Apply a clearfix to the container to ensure it wraps around floated elements */
.container::after {
  content: "";
  clear: both;
  display: table;
}

/* Define styles for the columns */
.column {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 15px; /* Adjust padding as needed */
}


.page-container {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Adjust the gap between columns as needed */
}

.column {
  flex: 1;
}

@media (max-width: 1199px) {
  .page-container {
    flex-direction: column;
  }
  .column {
    width: 100%; /* Ensure columns take full width in the stacked layout */
  }
}



/* Optional: Add specific styles for each column */
#left-column {
  background-color: #ffffff;
  padding-left: 1em;
}

#right-column {
  background-color: #ffffff;
  padding-right: 1em;
}

.main-headline {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 3.25rem;
}

.sub-headline {
  font-size: 1.5em;
  padding-bottom: 1em;
}

input[type=file] {
  border-bottom: none;
  padding-right: 1em;
}


input::file-selector-button {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}


.csv-button {
  background-color: #4a8c8a;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.csv-button:hover,
.csv-button:focus {
  background-color: #80bcba;
}


.source-button {
  /* Inherits properties from csv-button */
  composes: csv-button;

  /* Override specific properties */
  font-size: 10px;
  height: 30px;
  margin-bottom: 2.5rem;
  line-height: 15px;
  padding: 4px 12px;
}

.next-button {
  margin-left: 10px;
}




/* Home Page */



.divider {
  border: none;
  border-top: 2px solid black; /* Dark gray color */
  margin: 1rem 0rem; /* Space above and below the line */
  width: 50%; /* Line width */
  margin-left: auto;
  margin-right: auto;
}

.file-requirements {
  padding: 1.5em;
}

.dataframe-length {
  font-weight: bold;
  padding-bottom: 1rem;
}








/* Checkout Page */

.checkout-text {
  font-weight: bold;
  padding-left: 1rem;
  margin-bottom: 1.5rem;
}


.checkout-dataframe-length {
  font-weight: bold;
  padding-top: 1rem;
  padding-left: 1rem;
}

.checkout-dataframe-freq {
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.checkout-page-container {
  min-width: 80rem;
}


/* .data-preview-table {
  margin-bottom: 0.25em;
} */

.example-table-text {
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.ellipsis {
  font-size: 3em;
  text-align: center;
  margin-bottom: 0.5em;
  opacity: 0.6;
}


/* Set the width of the first column */
/* th:nth-child(1),
td:nth-child(1) {
  width: 100px;
} */

.data-preview-first-column {
  width: 100px;
}

/* Set the width of the second column */
/* th:nth-child(2),
td:nth-child(2) {
  width: 100px;
} */

.data-preview-second-column {
  width: 100px;
}


.validation-checks-message {
  color: #4a8c8a;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: bold;
}

.text-box {
  font-size: 1em;
  padding: 0.75em;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  /* padding: 0 20px; */
  box-sizing: border-box;
}

.text-container {
  padding-top: 2em;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 66.25%; /*56.25*/
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  padding-top: 2em;
  height: 100%;
  width: 100%;
  position: absolute;
}

.footer {
  text-align: center;
  padding: 1rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-height: 100vh; /* Set minimum height to fill the viewport */
  max-width: 1550px;
}


.ptf-site-wrapper {
  flex: 1; /* Grow to fill available space */
}

#success {
  padding-top: 1rem;
  padding-left: 3rem;
  width: 80%;
}

.success-text {
  font-size: 1.35rem;
  padding-left: 5.25rem;
}

.black {
  width: 12.5rem;
}

/* About Us page */

.large-heading {
  padding-top: 4rem;
  padding-left: 1rem;
}

.fz-18 {
  font-size: 1.125rem;
  margin: 0 0 1.875rem;
  margin-bottom: 1rem;
  margin-top: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  background-color: var(--ptf-color-white);
  color: #666;
  color: var(--ptf-color-2);
  font-family: Cerebri Sans;
  font-family: var(--ptf-primary-font);
  font-size: 1rem;
  letter-spacing: -.025em;
  line-height: 1.625;
  margin: 0!important;
  min-width: 20rem;
  padding: 1rem;
}

#message {
  height: 12rem;
}



/* Methodology Page */

.ptf-process-item__text {
  margin-left: -1.1rem;
}




.methodology-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.step {
  margin-bottom: 20px;
}

.step h2 {
  display: inline-block;
  width: 30px;
  margin-right: 10px;
  font-size: 24px;
  color: #333;
  text-align: right;
}

.step p {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
}


/* Login Page */

.small-heading {
  margin: 1rem 0rem 0rem 0.5rem;
}

.login-form {
  max-width: 300px;
  float: left;
  margin: 0 0 0 1rem;
}

.form-button {
  background-color: #4a8c8a;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 20px 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.password-field {
  padding-top: 0.75rem !important;
}

/* Analysis Page */

.fixed-height-container {
  max-height: 1500px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  position: relative; /* Ensures the table stays in place */
}

.main-plot-title {
  font-size: 1.75rem;
}

.series-name {
  min-width: 40%;
}

.scatterplot-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.plot-with-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lag-selector, .transformation-selector {
  display: flex;
  align-items: center; /* Align the label and select box vertically */
  margin-bottom: 1rem;
}

.lag-selector label, .transformation-selector label {
  margin-right: 0.5rem; /* Adjust as needed */
  white-space: nowrap; /* Prevents the label from wrapping */
  font-size: 1rem;
}

.lag-selector .lag-select {
  display: flex;
  padding-right: 1.75rem;
  padding-left: 1rem;
}

.transformation-selector {
  display: flex; /* Allows for margin adjustments without affecting the plot */
  margin-bottom: 1rem; /* Space between the selector and the plot */
}

.transformation-selector label {
  margin-right: 1rem; /* Adjust as needed */
  font-size: 1rem;
}

.transformation-selector #lag-select {
  display: flex;
}

.MuiButtonBase-root, .MuiButton-text {
  color: #4a8c8a !important;
  background-color: #fff !important;
}


.header-padding {
  padding-bottom: 1rem;
  cursor: pointer; /* Makes it clear that you can interact with the header */
}

.paragraph-padding {
  padding-bottom: 1.5rem;
  font-size: 1.25rem;
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensures that table borders are collapsed into a single border */
  margin-bottom: 1rem;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f0f0f0;
}

.series-name {
  font-weight: bold; /* Makes series name bold */
}

.ls-chevron {
  font: normal normal normal 2em/1 "LineIconsPro Light";
  padding-left: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.table-container {
  padding-bottom: 1.5rem;
}





/* Terms of Service Page */

.terms-container h2 {
  color: #333333;
}


.terms-container p {
  padding: 1rem 0rem 1rem 0rem;
}


/* Privacy Policy Page */

.privacy-container h2 {
  color: #333333;
}

.privacy-container p {
  padding: 1rem 0rem 1rem 0rem;
}

.privacy-container {
  margin: 1rem;
}

.terms-container {
  margin: 1rem;
}



/* Footer Component */

.main-footer {
  float: flex;
}

.all-rights-footer {
  padding-left: 7.5rem;
}

.privacy-footer {
  float: right;
  padding-left: 1rem;
}

.terms-footer {
  float: right;
  padding-right: 1rem;
}


/* Contact Form Component  */

.email-success {
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  color: #4a8c8a;
}


/* Login Page */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: left; /* Center align items if you want */
  width: 100%; /* Adjust width as needed */
}

.login-form {
  width: 90%; /* Adjust based on your form size */
  max-width: 400px; /* Example max-width for larger screens */
  margin-bottom: 20px; /* Adds space between form and link */
}

.password-reset-link {
  text-align: left; /* Center the link text if desired */
  width: 100%; /* Ensure it spans the full width */
  padding-left: 1rem;
}


/* Annotations Table */

.MuiBox-root.css-0 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* .css-qwm3qp-MuiPaper-root-MuiAlert-root {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-color: gray;
  padding: 0 !important;
}

.css-18vr5r7-MuiButtonBase-root-MuiChip-root {
  margin-top: 2rem;
  background-color: white !important;
  border-color: gray;
  border-style: solid;
  border-width: 1rem;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-colorInfo.MuiAlert-standardInfo.MuiAlert-standard {
  /* margin-top: 2rem; */
  background-color: white !important;
  /* border-color: gray;
  border-style: solid;
  border-width: 1px; */
}